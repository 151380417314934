import { Button, Container, Group, Space, Title } from "@mantine/core";

export function Page({
  title,
  primaryAction = {},
  secondaryActions = [],
  children,
  stickyHeader,
}) {
  const { content, onAction = () => {}, ...rest } = primaryAction;
  return (
    <div>
      <Container>
        <header
          className="flex items-center justify-between"
          style={
            stickyHeader
              ? {
                  backgroundColor: "#fff",
                  position: "sticky",
                  top: "70px",
                  zIndex: 99,
                  padding: "10px 0",
                }
              : null
          }
        >
          <div>
            <Title order={4}>{title}</Title>
          </div>
          <div>
            <Group>
              {Array.isArray(secondaryActions) &&
                secondaryActions?.map(
                  ({ onAction, content, ...rest }, index) => (
                    <Button
                      variant="default"
                      onClick={onAction}
                      {...rest}
                      key={index}
                    >
                      {content}
                    </Button>
                  )
                )}
              {primaryAction && (
                <Button
                  onClick={typeof onAction === "function" && onAction}
                  {...rest}
                >
                  {content}
                </Button>
              )}
            </Group>
          </div>
        </header>
        <div>
          <Space h="md" />
          {children}
          <Space h="md" />
        </div>
      </Container>
    </div>
  );
}
