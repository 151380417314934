import { useRef, useState } from "react";
import {
  Button,
  LoadingOverlay,
  Image,
  Grid,
  Space,
  Checkbox,
  Group,
} from "@mantine/core";
import { useNotifications } from "@mantine/notifications";

import {
  useGetAllImagesService,
  useUploadService,
  useDeleteOneImageService,
} from "@hooks";

export function MediaModal(props) {
  const [selectedImage, setSelectedImage] = useState();
  const notification = useNotifications();
  const uploadService = useUploadService();
  const deleteService = useDeleteOneImageService();
  const allImagesQuery = useGetAllImagesService();
  const inputFileRef = useRef();

  const imagesData = allImagesQuery?.data?.docs || [];
  const fileChangeHandler = (e) => {
    const file = e?.target?.files[0];

    if (!file) {
      throw new Error("Dosya bulunamadı");
    }

    const formData = new FormData();
    formData.append("file", file);

    uploadService.mutate(formData, {
      onSuccess: async (response) => {
        inputFileRef.current.value = "";
        console.log("Upload Response ===>", response);
        await allImagesQuery?.refetch();
      },
      onError: (error) => {
        console.log("Upload Error ===>", JSON.stringify(error, null, 2));
      },
    });
  };

  const deleteFileHandler = () => {
    if (!selectedImage) {
      throw new Error("Herhangi bir dosya seçilmedi");
    }

    deleteService?.mutate(selectedImage?._id, {
      onSuccess: async (response) => {
        setSelectedImage("");
        console.log("Delete Response ===>", response);
        await allImagesQuery?.refetch();
      },
      onError: (error) => {
        notification.showNotification({
          message:
            error?.response?.data?.message || error?.message || "Hata oluştu",
          color: "red",
        });
        console.log("Delete Error ===>", JSON.stringify(error, null, 2));
      },
    });
  };

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputFileRef}
        multiple={false}
        onChange={fileChangeHandler}
        accept="image/*"
      />
      <LoadingOverlay
        visible={allImagesQuery?.isLoading || uploadService?.isLoading}
      />
      <div
        style={{
          position: "sticky",
          top: "0",
          zIndex: 100,
          background: "#fff",
        }}
      >
        <div className="flex items-center justify-between">
          <Group>
            <Button
              variant="default"
              onClick={() => inputFileRef?.current?.click()}
            >
              Görsel Yükle
            </Button>
            {selectedImage && (
              <Button
                variant="default"
                onClick={deleteFileHandler}
                loading={deleteService?.isLoading}
              >
                Sil
              </Button>
            )}
          </Group>

          <Button
            mr="md"
            disabled={!selectedImage}
            onClick={() => {
              props?.context?.closeModal(props.id);
              props?.onSelect(selectedImage);
            }}
          >
            Seçileni Ekle
          </Button>
        </div>
        <Space h="md" />
      </div>
      <Grid style={{ width: "calc(100% - 10px)" }}>
        {Array.isArray(imagesData) &&
          imagesData?.map((i, index) => (
            <Grid.Col span={3} key={index}>
              <div
                style={{ position: "relative", cursor: "pointer" }}
                onClick={(e) =>
                  setSelectedImage((prev) => (prev?._id === i?._id ? null : i))
                }
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    paddingTop: "4px",
                    paddingLeft: "4px",
                    zIndex: 99,
                  }}
                >
                  <Checkbox
                    checked={selectedImage?._id === i?._id}
                    onChange={() => {}}
                  />
                </div>
                <Image src={i?.url} alt={i?.alt} height={100} fit="cover" />
              </div>
            </Grid.Col>
          ))}
      </Grid>
    </>
  );
}
