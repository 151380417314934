import { useEffect, useState } from "react";
import { useNotifications } from "@mantine/notifications";
import { AuthContext } from "@contexts";
import { useCheckAuthService, useLoginService, useLogoutService } from "@hooks";
import { LoadingOverlay } from "@mantine/core";

const initialValues = {
  user: null,
  isLoggedIn: false,
  token: null,
  mounted: false,
};

export function AuthProvider(props) {
  const [state, setState] = useState(initialValues);
  const notification = useNotifications();
  const loginMutation = useLoginService();
  const logoutMutation = useLogoutService();
  const checkAuthMutation = useCheckAuthService();

  useEffect(() => {
    checkAuthMutation.mutate(null, {
      onSuccess: (response) => {
        setState({
          mounted: true,
          token: response?.token,
          user: response?.user,
          isLoggedIn: true,
        });
      },
      onError: (error) => {
        console.error("error", error?.response, error?.message);
        notification.showNotification({
          color: "red",
          message: error?.response?.data?.message || error?.message,
        });
        setState({ ...initialValues, mounted: true });
      },
    });
    window.addEventListener("storage", syncLogout);

    return () => {
      window.removeEventListener("storage", syncLogout);
      window.localStorage.removeItem("logout");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const syncLogout = (event) => {
    if (event.key === "logout") {
      console.log("logged out from storage!");
      window.location.reload();
    }
  };

  const login = (email, password) => {
    loginMutation.mutate(
      { email, password },
      {
        onSuccess: (response) => {
          setState({
            mounted: true,
            token: response?.token,
            user: response?.user,
            isLoggedIn: true,
          });
          notification.showNotification({
            message: "Giriş işlemi başarılı",
          });
        },
        onError: (error) => {
          console.log("LOgin error", error?.response, error?.message);
        },
      }
    );
  };

  const logout = ({ email, password }) => {
    logoutMutation.mutate(null, {
      onSuccess: (response) => {
        setState({
          ...initialValues,
          mounted: true,
        });
        // to support logging out from all windows
        window.localStorage.setItem("logout", Date.now());
      },
      onError: (error) => {
        // setState({ ...initialValues, mounted: true });
        notification.showNotification({
          color: "red",
          message: error?.response?.data?.message || error?.message,
        });
        console.error("error", error?.response, error?.message);
      },
    });
  };
  return (
    <AuthContext.Provider
      value={{ ...state, login, loginMutation, logout, logoutMutation }}
    >
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <LoadingOverlay
          visible={!state?.mounted || logoutMutation?.isLoading}
        />
        {state?.mounted ? props.children : ""}
      </div>
    </AuthContext.Provider>
  );
}
