/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef } from "react";
import EditorJS from "@editorjs/editorjs";
import Paragraph from "@editorjs/paragraph";
import Quote from "@editorjs/quote";
import Header from "@editorjs/header";
import Delimiter from "@editorjs/delimiter";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import LinkTool from "@editorjs/link";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Underline from "@editorjs/underline";
import Marker from "@editorjs/marker";
import "./index.css";
import { API_BASE_URL } from "@constants";
import { Api } from "@utils";

const EDITTOR_HOLDER_ID = "editorjs";

export const TextEditor = (props) => {
  const ejInstance = useRef();

  // This will run only once
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      ejInstance.current.destroy();
      ejInstance.current = null;
    };
  }, []);

  const initEditor = () => {
    const editor = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      logLevel: "ERROR",
      data: props.value,
      onReady: () => {
        ejInstance.current = editor;
        typeof props.onReady === "function" && props.onReady(editor);
      },
      placeholder:
        "İçeriklerinizi buradan oluşturmaya başlayın. Clickbait içerikler girerseniz bozuşuruz :) (Sosyopolitik Blok Editor)",
      autofocus: false,
      tools: {
        header: {
          class: Header,
          inlineToolbar: true,
        },
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
        },
        delimiter: Delimiter,
        list: {
          class: List,
          inlineToolbar: true,
        },
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile(file) {
                const formdata = new FormData();
                formdata.append("image", file);
                return Api.post("/media/only-upload-aws", formdata).then(
                  (res) => res?.data
                );
              },
              uploadByUrl(url) {
                // your ajax request for uploading
                return Api.post("/media/upload-from-url", { url }).then(() => {
                  return {
                    success: 1,
                    file: {
                      url: "https://codex.so/upload/redactor_images/o_e48549d1855c7fc1807308dd14990126.jpg",
                      // any other image data you want to store, such as width, height, color, extension, etc
                    },
                  };
                });
              },
            },
          },
        },
        embed: {
          class: Embed,
        },
        linkTool: {
          class: LinkTool,
          config: {
            endpoint: `${API_BASE_URL}/fetch-url`, // Your backend endpoint for url data fetching,
          },
        },
        table: {
          class: Table,
          inlineToolbar: true,
        },
        underline: {
          class: Underline,
          inlineToolbar: true,
        },
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
      },
    });
  };

  return (
    <Fragment>
      <div style={{ width: "100%" }} id={EDITTOR_HOLDER_ID}>
        {" "}
      </div>
    </Fragment>
  );
};
