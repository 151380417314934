import { lazy } from "react";

export const categoryStackConfig = [
  {
    path: "/categories/:categoryId/update",
    component: lazy(() => import("./UpdateCategory")),
  },
  {
    path: "/categories/new",
    component: lazy(() => import("./AddCategory")),
  },
  {
    path: "/categories",
    component: lazy(() => import("./CategoryList")),
  },
];
