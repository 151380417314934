import { lazy } from "react";

export const postStackConfig = [
  {
    path: "/posts/:postId/update",
    component: lazy(() => import("./UpdatePost")),
  },
  {
    path: "/posts/new",
    component: lazy(() => import("./AddPost")),
  },
  {
    path: "/posts",
    component: lazy(() => import("./PostList")),
  },
];
