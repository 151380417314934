import { useState } from "react";
import {
  AppShell,
  Burger,
  Button,
  Header,
  MediaQuery,
  Menu,
  Navbar,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { FiSettings, FiLogOut } from "react-icons/fi";

import { useAuth } from "@hooks";
import { Logo } from "components";
import { MainLinks } from "./index";

export function AppLayout(props) {
  const [opened, setOpened] = useState(false);
  const { user, logout } = useAuth();
  const theme = useMantineTheme();

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      fixed
      navbar={
        <Navbar
          padding="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 300, lg: 200 }}
        >
          <Navbar.Section>
            <MainLinks />
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={70} padding="md">
          <div className="flex items-center h-full">
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <div className="flex items-center justify-between flex-1">
              <div className="flex items-center">
                <Text>
                  <Logo />
                </Text>
              </div>
              <Menu control={<Button variant="white">{user?.email}</Button>}>
                <Menu.Item icon={<FiSettings />}>Ayarlar</Menu.Item>
                <Menu.Item icon={<FiLogOut />} onClick={logout}>
                  Çıkış Yap
                </Menu.Item>
              </Menu>
            </div>
          </div>
        </Header>
      }
    >
      {props.children}
    </AppShell>
  );
}
