import { Api } from "@utils";

const scope = "/tags";

export const getAllTagsService = async () => {
  const res = await Api.get(`${scope}`);
  return res?.data;
};

export const createOneTagService = async (payload) => {
  const res = await Api.post(`${scope}`, payload);
  return res?.data;
};

export const getOneTagService = async (id) => {
  const res = await Api.get(`${scope}/${id}`);
  return res?.data;
};

export const updateOneTagService = async ({ id, ...rest }) => {
  const res = await Api.patch(`${scope}/${id}`, rest);
  return res?.data;
};

export const deleteOneTagService = async (id) => {
  const res = await Api.delete(`${scope}/id`);
  return res?.data;
};
