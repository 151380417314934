import { Api } from "@utils";

const scope = "/media";

export const uploadService = async (payload) => {
  const res = await Api.post(`${scope}/upload`, payload);
  return res?.data;
};

export const getAllImages = async (payload) => {
  const res = await Api.get(`${scope}/`, payload);
  return res?.data;
};

export const deleteOneImage = async (id) => {
  const res = await Api.delete(`${scope}/${id}`);
  return res?.data;
};
