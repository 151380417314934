import { useMutation, useQuery } from "react-query";

import {
  createOneTagService,
  deleteOneTagService,
  getAllTagsService,
  getOneTagService,
  updateOneTagService,
} from "@services";

export const useGetAllTagsService = () => useQuery("tags", getAllTagsService);
export const useGetOneTagService = (id) =>
  useQuery(["tags", id], () => getOneTagService(id));

export const useCreateOneTagService = () => useMutation(createOneTagService);
export const useUpdateOneTagService = () => useMutation(updateOneTagService);

export const useDeleteOneTagService = () => useMutation(deleteOneTagService);
