import { lazy } from "react";

export const tagStackConfig = [
  {
    path: "/tags/:tagId/update",
    component: lazy(() => import("./UpdateTag")),
  },
  {
    path: "/tags/new",
    component: lazy(() => import("./AddTag")),
  },
  {
    path: "/tags",
    component: lazy(() => import("./TagList")),
  },
];
