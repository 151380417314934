import { Title } from "@mantine/core";
import { Link } from "components";

export function Logo(props) {
  return (
    <Title order={4}>
      <Link href="/">Sosyopolitik</Link>
    </Title>
  );
}
