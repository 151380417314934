import { Api } from "@utils";

const scope = "/posts";

export const getAllPostsService = async () => {
  const res = await Api.get(`${scope}`);
  return res?.data;
};

export const createOnePostService = async (payload) => {
  const res = await Api.post(`${scope}`, payload);
  return res?.data;
};

export const getOnePostService = async (id) => {
  const res = await Api.get(`${scope}/${id}`);
  return res?.data;
};

export const updateOnePostService = async ({ id, ...rest }) => {
  const res = await Api.patch(`${scope}/${id}`, rest);
  return res?.data;
};

export const deleteOnePostService = async (id) => {
  const res = await Api.delete(`${scope}/id`);
  return res?.data;
};
