import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";

import { useAuth } from "@hooks";
import { AppLayout } from "components";
import { Login, routeConfig } from "containers";

export const App = (props) => {
  const { isLoggedIn } = useAuth();
  if (!isLoggedIn) {
    return <UnauthenticatedApp />;
  }
  return <AuthenticatedApp />;
};

export function UnauthenticatedApp(props) {
  return <Login />;
}

export function AuthenticatedApp(props) {
  return (
    <AppLayout>
      <Suspense fallback={<LoadingOverlay visible={true} />}>
        <Routes>
          {routeConfig?.map(({ path, component: Component }) => (
            <Route path={path} element={<Component />} key={path} />
          ))}
        </Routes>
      </Suspense>
    </AppLayout>
  );
}
