import { Api } from "@utils";

const scope = "/auth/admin";

export const loginService = async ({ email, password }) => {
  const res = await Api.post(`${scope}/login`, { email, password });
  return res?.data;
};

export const checkAuthService = async () => {
  const res = await Api.post(`${scope}/check-auth`, null);
  return res?.data;
};

export const logoutService = async () => {
  const res = await Api.post(`${scope}/logout`, null);
  return res?.data;
};
