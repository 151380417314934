import {
  TextInput,
  Button,
  Group,
  PasswordInput,
  Container,
  Card,
  Title,
  Alert,
  Space,
} from "@mantine/core";
import { useFormik } from "formik";

import { useAuth } from "@hooks";
import validationSchema from "./validationSchema";

const initialValues = { email: "", password: "" };
export function Login(props) {
  const { login, loginMutation } = useAuth();

  const onSubmitHandler = ({ email, password }) => {
    login(email, password);
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
  } = useFormik({ initialValues, onSubmit: onSubmitHandler, validationSchema });

  return (
    <Container size="xs">
      <Space h="lg" />
      <Card shadow="lg" padding="md">
        <form onSubmit={handleSubmit}>
          <Group direction="column">
            <Title order={4}>Sosyopolitik Admin Panel</Title>
            {loginMutation.isError && (
              <Alert title="Hata" color="red" style={{ width: "100%" }}>
                {loginMutation?.error?.response?.data?.message ||
                  loginMutation?.error?.message}
              </Alert>
            )}
            <TextInput
              name="email"
              onChange={handleChange}
              value={values?.email}
              onBlur={handleBlur}
              required
              label="Email"
              style={{ width: "100%" }}
              error={touched.email && errors.email}
            />
            <PasswordInput
              name="password"
              onChange={handleChange}
              value={values?.password}
              onBlur={handleBlur}
              label="Şifre"
              required
              style={{ width: "100%" }}
              error={touched.password && errors.password}
            />
            <Button
              loading={loginMutation?.isLoading}
              disabled={!isValid}
              type="submit"
            >
              Giriş Yap
            </Button>
          </Group>
        </form>
      </Card>
    </Container>
  );
}
