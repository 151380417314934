import { useMutation, useQuery } from "react-query";

import {
  createOnePostService,
  deleteOnePostService,
  getAllPostsService,
  getOnePostService,
  updateOnePostService,
} from "@services";

export const useGetAllPostsService = () =>
  useQuery("posts", getAllPostsService);
export const useGetOnePostService = (id) =>
  useQuery(["posts", id], () => getOnePostService(id));

export const useCreateOnePostService = () => useMutation(createOnePostService);
export const useUpdateOnePostService = () => useMutation(updateOnePostService);

export const useDeleteOnePostService = () => useMutation(deleteOnePostService);
