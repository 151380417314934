import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
  token: null,
  mounted: false,
  login: (email, password) => {},
  loginMutation: { mutate: () => {}, isLoading: false },
  checkToken: () => {},
  logout: () => {},
  logoutMutation: () => {},
});
