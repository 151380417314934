import {
  postStackConfig,
  categoryStackConfig,
  tagStackConfig,
} from "./index.js";

export const routeConfig = [
  ...postStackConfig,
  ...categoryStackConfig,
  ...tagStackConfig,
];
