import axios from "axios";

import { API_BASE_URL } from "@constants";

export const Api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

Api.interceptors.response.use(undefined, (error) => {
  if (typeof error.response !== "undefined") {
    if (error.response.status === 401) {
      // TODO: add logout logic
    }
    console.log("hered");
  } else {
  }
  console.log("Dsadadsa");

  return Promise.reject(error);
});
