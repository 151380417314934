import { Text } from "@mantine/core";
import { Link as RouterLink } from "react-router-dom";

export function Link({ href, children, ...rest }) {
  return (
    <Text variant="link" component={RouterLink} to={href} {...rest}>
      {children}
    </Text>
  );
}
