import * as yup from "yup";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email alanı zorunludur")
    .email("Lütfen geçerli bir Email adresi giriniz"),
  password: yup
    .string()
    .required("Şifre alanı zorunludur")
    .min(8, "Şifre alanı en az 8 karakter içermelidir")
    .max(30, "Şifre alanı en fazla 30 karakterden oluşabilir"),
});

export default validationSchema;
