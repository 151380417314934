import { AuthContext } from "@contexts";
import { useContext } from "react";

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth hook must be used in AuthProvider component.");
  }

  return context;
};
