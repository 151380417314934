import { Api } from "@utils";

const scope = "/categories";

export const getAllCategoriesService = async () => {
  const res = await Api.get(`${scope}`);
  return res?.data;
};

export const createOneCategoryService = async (payload) => {
  const res = await Api.post(`${scope}`, payload);
  return res?.data;
};

export const getOneCategoryService = async (id) => {
  const res = await Api.get(`${scope}/${id}`);
  return res?.data;
};

export const updateOneCategoryService = async ({ id, ...rest }) => {
  const res = await Api.patch(`${scope}/${id}`, rest);
  return res?.data;
};

export const deleteOneCategoryService = async (id) => {
  const res = await Api.delete(`${scope}/id`);
  return res?.data;
};
