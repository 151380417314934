import { useMutation, useQuery } from "react-query";

import {
  createOneCategoryService,
  deleteOneCategoryService,
  getAllCategoriesService,
  getOneCategoryService,
  updateOneCategoryService,
} from "@services";

export const useGetAllCategoriesService = () =>
  useQuery("categories", getAllCategoriesService);
export const useGetOneCategoryService = (id) =>
  useQuery(["categories", id], () => getOneCategoryService(id));

export const useCreateOneCategoryService = () =>
  useMutation(createOneCategoryService);
export const useUpdateOneCategoryService = () =>
  useMutation(updateOneCategoryService);

export const useDeleteOneCategoryService = () =>
  useMutation(deleteOneCategoryService);
